import React, { Suspense, lazy } from "react";
import { Route, Routes } from "react-router-dom";
import Header from "../components/common/Header";
import PrivateRoute from "./PrivateRoute";
import { AuthProvider } from "../contexts/AuthContext";

const PageNotFound = lazy(() => import("../pages/page-not-found"));
const LogIn = lazy(() => import("../pages/auth/login"));
const SignUp = lazy(() => import("../pages/auth/signup"));
const ForgotPassowrd = lazy(() => import("../pages/auth/forgot-passowrd"));
const Home = lazy(() => import("../pages/home"));
const News = lazy(() => import("../pages/news"));
const NewsContent = lazy(() => import("../pages/news/news-content"));
const Submit = lazy(() => import("../pages/submit"));

const Routers = () => {
  return (
    <>
      <Suspense fallback={<p>Loading...</p>}>
        <AuthProvider>
          <Header />
          <Routes>
            <Route
              exact
              path="/"
              element={
                <PrivateRoute>
                  <Home />
                </PrivateRoute>
              }
            />
            <Route exact path="/login" element={<LogIn />} />
            <Route exact path="/signup" element={<SignUp />} />
            <Route exact path="/forgot-password" element={<ForgotPassowrd />} />
            <Route
              exact
              path="/news"
              element={
                <PrivateRoute>
                  <News />
                </PrivateRoute>
              }
            />
            <Route
              exact
              path="/news/news-content"
              element={
                <PrivateRoute>
                  <NewsContent />
                </PrivateRoute>
              }
            />
            <Route
              exact
              path="/submit"
              element={
                <PrivateRoute>
                  <Submit />
                </PrivateRoute>
              }
            />

            <Route path="*" element={<PageNotFound />} />
          </Routes>
        </AuthProvider>
      </Suspense>
    </>
  );
};

export default Routers;
